import { Languages } from '@taxfix/types';
import { Rights } from './user-rights.types';

// TODO review this types and align with users-api

type PublicParameters = {
  email: string;
  lang: string;
  langStr: string;
  created?: Date;
  updated?: Date;
  deleted?: Date | null;
  confirmed: Date | null;
  activated: Date;
  whoAreYou?: Record<string, unknown>;
  blocked?: Date | null;
  name?: string;
};

type PrivateParameters = {
  pwd?: string | null;
  userKey?: string;
};

type AdditionalParameters = {
  rights?: Rights;
  tacVersion?: string;
};

type LegacyColumns = {
  allocationRoleId?: string;
  whoAreYou2015?: string;
};

export enum Permissions {
  admin_archive_user = 'admin_archive_user',
  admin_identity_confirm = 'admin_identity_confirm',
  admin_identity_external = 'admin_identity_external',
  admin_identity_external_es = 'admin_identity_external_es',
  admin_identity_view = 'admin_identity_view',
  admin_manage_rights = 'admin_manage_rights',
  admin_view = 'admin_view',
  api_certified_storage_it = 'api_certified_storage_it',
  api_diagnostic = 'api_diagnostic',
  api_difm_de = 'api_difm_de',
  api_difm_de_reject_disputed = 'api_difm_de_reject_disputed',
  api_document_de = 'api_document_de',
  api_document_es = 'api_document_es',
  api_document_fr = 'api_document_fr',
  api_document_it = 'api_document_it',
  api_document_nl = 'api_document_nl',
  api_expert_review_de = 'api_expert_review_de',
  api_iban_edit_de = 'api_iban_edit_de',
  api_identification_unarchive_de = 'api_identification_unarchive_de',
  api_interaction_edit = 'api_interaction_edit',
  api_live_help_de = 'api_live_help_de',
  api_prefill_batch_it = 'api_prefill_batch_it',
  api_prefill_de = 'api_prefill_de',
  api_prefill_es = 'api_prefill_es',
  api_prefill_fr = 'api_prefill_fr',
  api_prefill_it = 'api_prefill_it',
  api_prefill_nl = 'api_prefill_nl',
  api_prefill_unarchive_it = 'api_prefill_unarchive_it',
  api_submission = 'api_submission',
  api_submission_delete = 'api_submission_delete',
  api_submission_delete_reasons = 'api_submission_delete_reasons',
  api_submission_es = 'api_submission_es',
  api_submission_fr = 'api_submission_fr',
  api_submission_hard_state_changes = 'api_submission_hard_state_changes',
  api_submission_it = 'api_submission_it',
  api_submission_nl = 'api_submission_nl',
  api_submission_submit = 'api_submission_submit',
  api_submission_unarchive_de = 'api_submission_unarchive_de',
  api_submission_unarchive_es = 'api_submission_unarchive_es',
  api_submission_unarchive_fr = 'api_submission_unarchive_fr',
  api_submission_unarchive_it = 'api_submission_unarchive_it',
  api_submission_unarchive_nl = 'api_submission_unarchive_nl',
  api_user = 'api_user',
  api_user_admin_rights = 'api_user_admin_rights',
  user_activate = 'user_activate',
  private = 'private',
}

export type UserModel = PublicParameters & PrivateParameters & LegacyColumns;
export type User = PublicParameters & AdditionalParameters;
export type UserId = number;
export type UserWithId = User & { id: UserId };

export type UserLang = `${Languages}`;
