import { CallOptions, CoreSDKClient } from '@taxfix/core-sdk';
import { UserWithId } from '../types';
import {
  ListUserHistoryResponse,
  ListUsersResponse,
} from '../types/response.types';
import { UserCsAttributesWithId } from '../types/user-cs-attributes.types';
import {
  CreateEmailChangeRequest,
  CreateOrUpdateCSAttributesAdminRequest,
  DefaultPaginationParams,
  FreezeUserRequest,
  GetCSAttributesAdminRequest,
  GetUserRequest,
  ListUserHistoryRequest,
  ListUsersRequest,
  SearchByRequestBody,
  SearchByResponse,
  UnblockUserRequest,
  UnfreezeUserRequest,
  UpdateUserRequest,
} from './types';

const ADMIN_PREFIX = '/admin/users';

export class UsersAPIAdminClient extends CoreSDKClient {
  async getUser(
    params: GetUserRequest,
    opts: CallOptions = {},
  ): Promise<UserWithId> {
    return this.perform(opts, {
      method: 'GET',
      url: `${ADMIN_PREFIX}/v1/users/${params.userId}`,
    });
  }

  /**
   * List users
   * @deprecated Use searchBy instead
   *
   * @param {ListUsersResponse} params
   * @param {CallOptions} opts
   *
   * @returns {Promise<ListUsersResponse>}
   */
  async listUsers(
    params: ListUsersRequest,
    opts: CallOptions = {},
  ): Promise<ListUsersResponse> {
    return this.perform<ListUsersResponse>(opts, {
      method: 'GET',
      url: `${ADMIN_PREFIX}/v1/users`,
      params,
    });
  }

  async searchBy(
    body: SearchByRequestBody,
    params: DefaultPaginationParams,
    opts: CallOptions = {},
  ): Promise<SearchByResponse> {
    return this.perform<SearchByResponse>(opts, {
      method: 'POST',
      data: body,
      url: `${ADMIN_PREFIX}/v1/users/search-by`,
      params,
    });
  }

  async unblockUser(
    params: UnblockUserRequest,
    opts: CallOptions = {},
  ): Promise<UserWithId> {
    return this.perform(opts, {
      method: 'DELETE',
      url: `${ADMIN_PREFIX}/v1/users/${params.userId}/blocked`,
    });
  }

  async freezeUser(
    { userId, reason }: FreezeUserRequest,
    opts: CallOptions = {},
  ): Promise<void> {
    await this.perform(opts, {
      method: 'PUT',
      url: `${ADMIN_PREFIX}/v1/users/${userId}/frozen`,
      data: {
        reason: reason,
      },
    });
  }

  async unfreezeUser(
    { userId }: UnfreezeUserRequest,
    opts: CallOptions = {},
  ): Promise<void> {
    await this.perform(opts, {
      method: 'DELETE',
      url: `${ADMIN_PREFIX}/v1/users/${userId}/frozen`,
      data: {},
    });
  }

  async getCSAttributes(
    { userId }: GetCSAttributesAdminRequest,
    opts: CallOptions = {},
  ): Promise<UserCsAttributesWithId> {
    return await this.perform(opts, {
      method: 'GET',
      url: `${ADMIN_PREFIX}/v1/users/${userId}/cs-attributes`,
    });
  }

  async createOrUpdateCSAttributes(
    { userId, attributes }: CreateOrUpdateCSAttributesAdminRequest,
    opts: CallOptions = {},
  ): Promise<UserCsAttributesWithId> {
    return await this.perform(opts, {
      method: 'PUT',
      url: `${ADMIN_PREFIX}/v1/users/${userId}/cs-attributes`,
      data: {
        attributes,
      },
    });
  }

  async updateUser(
    { userId, lang }: UpdateUserRequest,
    opts: CallOptions = {},
  ): Promise<UserWithId> {
    return this.perform(opts, {
      method: 'PATCH',
      url: `${ADMIN_PREFIX}/v1/users/${userId}/language`,
      data: { lang },
    });
  }

  async listUserHistory(
    { userId }: ListUserHistoryRequest,
    opts: CallOptions = {},
  ): Promise<ListUserHistoryResponse> {
    const { data } = await this.perform<ListUserHistoryResponse>(opts, {
      method: 'GET',
      url: `${ADMIN_PREFIX}/v1/users/${userId}/history`,
    });

    return {
      data: data.map(history => {
        return {
          ...history,
          // TODO: Make this more generic/use OAS to generate this
          createdAt: new Date(history.createdAt),
        };
      }),
    };
  }

  async createEmailChange(
    { userId, ...body }: CreateEmailChangeRequest,
    opts: CallOptions = {},
  ): Promise<void> {
    return this.perform<void>(opts, {
      method: 'POST',
      data: body,
      url: `${ADMIN_PREFIX}/v1/users/${userId}/email-change`,
    });
  }
}
