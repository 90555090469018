import { __extends } from "tslib";
export var IntlErrorCode;
(function (IntlErrorCode) {
    IntlErrorCode["FORMAT_ERROR"] = "FORMAT_ERROR";
    IntlErrorCode["UNSUPPORTED_FORMATTER"] = "UNSUPPORTED_FORMATTER";
    IntlErrorCode["INVALID_CONFIG"] = "INVALID_CONFIG";
    IntlErrorCode["MISSING_DATA"] = "MISSING_DATA";
    IntlErrorCode["MISSING_TRANSLATION"] = "MISSING_TRANSLATION";
})(IntlErrorCode || (IntlErrorCode = {}));
var IntlError = /** @class */ (function (_super) {
    __extends(IntlError, _super);
    function IntlError(code, message, exception) {
        var _this = _super.call(this, "[@formatjs/intl Error " + code + "] " + message + " \n" + (exception ? "\n" + exception.message + "\n" + exception.stack : '')) || this;
        _this.code = code;
        if (typeof Error.captureStackTrace === 'function') {
            Error.captureStackTrace(_this, IntlError);
        }
        return _this;
    }
    return IntlError;
}(Error));
export { IntlError };
var UnsupportedFormatterError = /** @class */ (function (_super) {
    __extends(UnsupportedFormatterError, _super);
    function UnsupportedFormatterError(message, exception) {
        return _super.call(this, "UNSUPPORTED_FORMATTER" /* UNSUPPORTED_FORMATTER */, message, exception) || this;
    }
    return UnsupportedFormatterError;
}(IntlError));
export { UnsupportedFormatterError };
var InvalidConfigError = /** @class */ (function (_super) {
    __extends(InvalidConfigError, _super);
    function InvalidConfigError(message, exception) {
        return _super.call(this, "INVALID_CONFIG" /* INVALID_CONFIG */, message, exception) || this;
    }
    return InvalidConfigError;
}(IntlError));
export { InvalidConfigError };
var MissingDataError = /** @class */ (function (_super) {
    __extends(MissingDataError, _super);
    function MissingDataError(message, exception) {
        return _super.call(this, "MISSING_DATA" /* MISSING_DATA */, message, exception) || this;
    }
    return MissingDataError;
}(IntlError));
export { MissingDataError };
var MessageFormatError = /** @class */ (function (_super) {
    __extends(MessageFormatError, _super);
    function MessageFormatError(message, locale, descriptor, exception) {
        var _this = _super.call(this, "FORMAT_ERROR" /* FORMAT_ERROR */, message + " \nLocale: " + locale + "\nMessageID: " + (descriptor === null || descriptor === void 0 ? void 0 : descriptor.id) + "\nDefault Message: " + (descriptor === null || descriptor === void 0 ? void 0 : descriptor.defaultMessage) + "\nDescription: " + (descriptor === null || descriptor === void 0 ? void 0 : descriptor.description) + " \n", exception) || this;
        _this.descriptor = descriptor;
        return _this;
    }
    return MessageFormatError;
}(IntlError));
export { MessageFormatError };
var MissingTranslationError = /** @class */ (function (_super) {
    __extends(MissingTranslationError, _super);
    function MissingTranslationError(descriptor, locale) {
        var _this = _super.call(this, "MISSING_TRANSLATION" /* MISSING_TRANSLATION */, "Missing message: \"" + descriptor.id + "\" for locale \"" + locale + "\", using " + (descriptor.defaultMessage ? 'default message' : 'id') + " as fallback.") || this;
        _this.descriptor = descriptor;
        return _this;
    }
    return MissingTranslationError;
}(IntlError));
export { MissingTranslationError };
