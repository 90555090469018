import { __assign } from "tslib";
import { pegParse } from './src/parser';
import { normalizeHashtagInPlural } from './src/normalize';
export * from './src/types';
export * from './src/parser';
export function parse(input, opts) {
    opts = __assign({ normalizeHashtagInPlural: true, shouldParseSkeleton: true }, (opts || {}));
    var els = pegParse(input, opts);
    if (opts.normalizeHashtagInPlural) {
        normalizeHashtagInPlural(els);
    }
    return els;
}
