import { CallOptions, CoreSDKClient } from '@taxfix/core-sdk';
import { UserWithId } from '../types';
import { UserCsAttributesWithId } from '../types/user-cs-attributes.types';
import type {
  BlockUserRequest,
  CreateOrUpdateCSAttributesPrivateRequest,
  GetCSAttributesPrivateRequest,
  SearchByRequestBody,
  SearchByResponse,
} from './types';

const PRIVATE_PREFIX = '/private/users';

export class UsersAPIPrivateClient extends CoreSDKClient {
  async blockUser(
    params: BlockUserRequest,
    opts: CallOptions = {},
  ): Promise<UserWithId> {
    return this.perform(opts, {
      data: {},
      url: `${PRIVATE_PREFIX}/v1/users/${params.userId}/blocked`,
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
      },
    });
  }

  async blockPasswordlessUser(
    params: BlockUserRequest,
    opts: CallOptions = {},
  ): Promise<UserWithId> {
    return this.perform(opts, {
      data: {},
      url: `${PRIVATE_PREFIX}/v2/users/${params.userId}/blocked`,
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
      },
    });
  }

  async searchBy(
    body: SearchByRequestBody,
    opts: CallOptions = {},
  ): Promise<SearchByResponse> {
    return this.perform<SearchByResponse>(opts, {
      method: 'POST',
      data: body,
      url: `${PRIVATE_PREFIX}/v1/users/search-by`,
    });
  }

  async createOrUpdateCSAttributes(
    { userId, attributes }: CreateOrUpdateCSAttributesPrivateRequest,
    opts: CallOptions = {},
  ): Promise<UserCsAttributesWithId> {
    return this.perform<UserCsAttributesWithId>(opts, {
      method: 'PUT',
      data: { attributes },
      url: `${PRIVATE_PREFIX}/v1/users/${userId}/cs-attributes`,
      headers: {
        'content-type': 'application/json',
      },
    });
  }

  async getCSAttributes(
    { userId }: GetCSAttributesPrivateRequest,
    opts: CallOptions = {},
  ): Promise<UserCsAttributesWithId> {
    return await this.perform(opts, {
      method: 'GET',
      url: `${PRIVATE_PREFIX}/v1/users/${userId}/cs-attributes`,
    });
  }
}
