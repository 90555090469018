import { UserRights } from './user-rights.types';

export enum TokenType {
  Confirm = 1,
  ResetPassword = 2,
  Access = 3,
  ViewPdf = 4,
  Push = 5,
  ChangeEmail = 6,
}

export type Token = {
  userId: number;
  typeId: TokenType;
  token: string;
  created?: Date;
  expires?: Date;
  origin?: string;
  meta?: any;
};

export type TokenModel = Token;
export type TokenId = number;
export type TokenWithId = Token & {
  id: TokenId;
};

export type TokenWithRights = TokenWithId & UserRights;
