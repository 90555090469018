export var DATE_TIME_PROPS = [
    'weekday',
    'era',
    'year',
    'month',
    'day',
    'hour',
    'minute',
    'second',
    'timeZoneName',
];
export var removalPenalty = 120;
export var additionPenalty = 20;
export var differentNumericTypePenalty = 15;
export var longLessPenalty = 8;
export var longMorePenalty = 6;
export var shortLessPenalty = 6;
export var shortMorePenalty = 3;
