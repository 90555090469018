import { createFormattedComponent, createFormattedDateTimePartsComponent, } from './src/components/createFormattedComponent';
export { createIntlCache, UnsupportedFormatterError, InvalidConfigError, MissingDataError, MessageFormatError, MissingTranslationError, IntlErrorCode as ReactIntlErrorCode, IntlError as ReactIntlError, } from '@formatjs/intl';
export function defineMessages(msgs) {
    return msgs;
}
export function defineMessage(msg) {
    return msg;
}
export { default as injectIntl, Provider as RawIntlProvider, Context as IntlContext, } from './src/components/injectIntl';
export { default as useIntl } from './src/components/useIntl';
export { default as IntlProvider, createIntl } from './src/components/provider';
// IMPORTANT: Explicit here to prevent api-extractor from outputing `import('./src/types').CustomFormatConfig`
export var FormattedDate = createFormattedComponent('formatDate');
export var FormattedTime = createFormattedComponent('formatTime');
export var FormattedNumber = createFormattedComponent('formatNumber');
export var FormattedList = createFormattedComponent('formatList');
export var FormattedDisplayName = createFormattedComponent('formatDisplayName');
export var FormattedDateParts = createFormattedDateTimePartsComponent('formatDate');
export var FormattedTimeParts = createFormattedDateTimePartsComponent('formatTime');
export { FormattedNumberParts } from './src/components/createFormattedComponent';
export { default as FormattedRelativeTime } from './src/components/relative';
export { default as FormattedPlural } from './src/components/plural';
export { default as FormattedMessage } from './src/components/message';
export { default as FormattedDateTimeRange } from './src/components/dateTimeRange';
