import { __assign } from "tslib";
import * as React from 'react';
import { invariant } from '@formatjs/ecma402-abstract';
import { DEFAULT_INTL_CONFIG as CORE_DEFAULT_INTL_CONFIG } from '@formatjs/intl';
export function invariantIntlContext(intl) {
    invariant(intl, '[React Intl] Could not find required `intl` object. ' +
        '<IntlProvider> needs to exist in the component ancestry.');
}
export var DEFAULT_INTL_CONFIG = __assign(__assign({}, CORE_DEFAULT_INTL_CONFIG), { textComponent: React.Fragment });
/**
 * Takes a `formatXMLElementFn`, and composes it in function, which passes
 * argument `parts` through, assigning unique key to each part, to prevent
 * "Each child in a list should have a unique "key"" React error.
 * @param formatXMLElementFn
 */
export function assignUniqueKeysToParts(formatXMLElementFn) {
    return function (parts) {
        // eslint-disable-next-line prefer-rest-params
        return formatXMLElementFn(React.Children.toArray(parts));
    };
}
